<template>
  <el-dialog class="operation_dialog" v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">
    <template v-if="dialogVisibletitle === '创建新企业'">
      <div class="dp-f mt-20">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="90px"
          class="demo-ruleForm w100" label-position="left">
          <el-form-item label="公司名称" prop='companyName'>
            <el-input v-model="ruleForm.companyName" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="输入公司名称" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
          <el-form-item label="公司信用代码" prop='creditCode'>
            <el-input v-model="ruleForm.creditCode" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="输入公司信用代码" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
          <el-form-item label="公司法定代表人姓名" prop='juridicalPersonName'>
            <el-input v-model="ruleForm.juridicalPersonName" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="输入公司法定代表人姓名" :disabled="dialogVisibletitle==='修改企业'"/>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right;">
        <el-button type="primary" text @click="innerVisible = true">试一试自动识别？</el-button>
      </div>
        <el-dialog
          v-model="innerVisible"
          width="400px"
          title="上传营业执照"
          :close-on-click-modal="false"
          append-to-body
        >
          <el-upload
            class="upload-demo"
            ref="uploadRef"
            drag
            action="#"
            multiple
            :limit="1"
            :http-request="ImgHttpImport"
            :on-remove="resetFile"
            list-type="picture-card"
          >
            <div v-show="!state.nowFile">
              <el-icon class="el-icon--upload"><upload-filled /></el-icon>
              <div class="el-upload__text">
                将文件拖拉至此 或 <em>点击上传</em>
              </div>
              <!-- <div class="el-upload__text" style="margin-top: 10px;">
                jpg/png类型文件并且文件小于2MB
              </div> -->
              
            </div>
          </el-upload>
          <template #footer>
            <span class="dialog-footer dp-f fd-rr">
              <oabutton width='100' height='40' title="自动识别" CSStype=2 @buttonclick="uploadBizLicense"
                v-model:loading="innerDialogLoading"></oabutton>
            </span>
          </template>
        </el-dialog>
    </template>
  
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton width='999' style="border-radius: 4px;" height='40' title="确认" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { validChinaName, validSocialCode } from "@/utils/server/validate.js";
import { import_img_accept } from "@/utils/base/accept"
import { getfileTypeName } from "@/utils/server/file.js"
import { httpToken } from "@/utils/request";
import { UploadFilled } from '@element-plus/icons-vue'
import { uploadFile } from "@/utils/server/upload.js"
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const innerDialogLoading = ref(false)
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const innerVisible = ref(false)
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const state = reactive({
  nowFile: false
})
const uploadRef = ref()//上传组件ref

const ImgHttpImport = ((rawFile) => {
  console.log('rawFile', rawFile, rawFile.file.size)
  let fileTypeName = getfileTypeName(rawFile.file.name)
  let imgtype = ['jpg', 'JPG', 'jpeg', 'JPEG', 'png', 'PNG', 'bmp', 'BMP']
  console.log('fileTypeName', fileTypeName)
  if (rawFile.file.size / 1024 / 1024 > 2) {
    handleMessage('图片大小不能超过2MB')
    resetFile()
  } else if (imgtype.includes(fileTypeName)) {
    state.nowFile = rawFile
  } else {
    handleMessage('请上传正确图片格式!')
    resetFile()
  }
})

const resetFile = ((uploadFile, uploadFiles) => {
  console.log("resetFile", uploadFile, uploadFiles)
  state.nowFile = false
  unref(uploadRef).clearFiles()
})
// 校验中文姓名
const validChinaNameData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入真实姓名'))
  } else {
    if (validChinaName(value)) {
      callback()
    } else
      callback(new Error('真实姓名格式错误'))
  }
}
// 校验统一社会信用代码
const validSocialCodeData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入统一社会信用代码'))
  } else {
    if (validSocialCode(value)) {
      callback()
    } else
      callback(new Error('统一社会信用代码格式错误'))
  }
}
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  companyName:'',//公司名称
  creditCode:'',//公司信用代码
  juridicalPersonName:'',//公司法定代表人姓名
})
const rules = reactive({//from 表单校验
  companyName: [
    { required: true, message: '请输入公司名称', trigger: 'blur' },
  ],
  creditCode: [
    { validator: validSocialCodeData, trigger: 'blur' }
  ],
  juridicalPersonName: [
    { validator: validChinaNameData, trigger: 'blur' }
  ],
})
// 表单重置
const fromDataReset = (() => {
  objdata_ret(ruleForm)
})
const add = (() => {
  fromDataReset()
  dialogloading.value = false
  dialogVisibletitle.value = '创建新企业'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
const uploadBizLicense = (() => {
  if (!state.nowFile) {
    handleMessage("请上传工商营业执照")
    return
  }
  innerDialogLoading.value = true
  uploadFile(state.nowFile.file, "bizLicense").then(res => {
    console.log("uploadfile:", res)
    let fileId = res.data.id
    httpToken({
      method: "post",
      url: '/admin/certification/bizLicenseOcr',
      data: qs.stringify({
        'fileId': fileId
      })
    }).then(ocrRes => {
      console.log("ocr resp:", ocrRes)
      innerVisible.value = false
      state.nowFile = false
      innerDialogLoading.value = false
      unref(uploadRef).clearFiles()
      handleMessage("识别成功", 'success')
      ruleForm.companyName = ocrRes.data.entName
      ruleForm.creditCode = ocrRes.data.creditCode
      ruleForm.juridicalPersonName = ocrRes.data.leagalerName
    }).catch(() => {
      innerDialogLoading.value = false
      dialogloading.value = false
    })
  }).catch(err => {
    innerDialogLoading.value = false
    console.log("upload error: ", err)
  })

})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      if (dialogVisibletitle.value === '创建新企业') {
        dataGeneration(ruleForm)
      } 
    } else {
      dialogloading.value = false
    }
  })
}
// 数据生成
const dataGeneration = ((json, url = '/admin/certification/addCompany') => {
  httpToken({
    method: "post",
    url: url,
    data: json,
  }).then((res) => {
    dialogloading.value = false
    dialogVisible.value = false
    emit('Emit')
  }).catch(() => {
    dialogloading.value = false
  })
})
defineExpose({
  add,
});
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label{
  display: none;
}
::v-deep .el-upload-dragger {
  border: none !important;
  padding: 0 !important;
}

::v-deep .el-upload--picture-card {
  border: none !important;
}

::v-deep .el-upload--picture-card {
  --el-upload-picture-card-size: 100%;
}

::v-deep .el-upload-list--picture-card {
  --el-upload-list-picture-card-size: 100%;
  display: block;
}
.upload-demo {
  height: 100%;
}

</style>